<template>
  <div
    class="group-[&.scrolled]/landing:dark:bg-muted-950/95 group-[&.scrolled]/landing:border-muted-200 group-[&.scrolled]/landing:dark:border-muted-200 group-[&.scrolled]/landing:motion-safe:shadow-muted-300/30 group-[&.scrolled]/landing:motion-safe:dark:shadow-muted-800/20 flex items-center justify-between rounded-2xl  p-4 group-[&:not(.scrolled)]/landing:border-transparent group-[&.scrolled]/landing:bg-white/95 motion-safe:transition-all motion-safe:duration-200 group-[&.scrolled]/landing:motion-safe:shadow-xl">
    <div class="flex w-1/2 items-center gap-2 md:w-1/5">
      <NuxtLink to="/" class="ms-2 inline-flex" aria-label="Accomium">
        <TairoLogoText
          class="text-primary-500 group-[&.scrolled]/landing:h-6 group-[&:not(.scrolled)]/landing:h-7 motion-safe:transition-all motion-safe:duration-200"/>
      </NuxtLink>
    </div>
    <div class="flex w-1/2 items-center justify-end gap-4 md:w-1/5">
      <BaseThemeToggle aria-label="Toggle darkmode"/>
      <BaseButton shape="curved" color="primary" to="/auth/signin"> Signin
      </BaseButton>
    </div>
  </div>
</template>

